"use client"
import parse from "html-react-parser";
import {useState} from 'react';
import {IAboutUsComponentProps} from '../../../lib/types/components';
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./AboutUsComponent.module.css"

const AboutUsComponent = (props: IAboutUsComponentProps) => {
    const {aboutUsData, isGetApp} = props;
    const data = aboutUsData;
    const [readMore, setReadMore] = useState(aboutUsData.para.length <= 1);

    const showMore = () => {
        setReadMore(true);
    }

    return (
        data && Object.keys(data).length ?
            <div className={multipleClassName("container-fluid", styles.containerFluid)}>
                <div className={multipleClassName("container", styles.container)}
                     style={isGetApp ? {marginTop: 0} : {marginTop: 30}}>
                    <h2 className={styles.heading}>{data.heading}</h2>
                    <div className={multipleClassName(styles.paraContainer, styles.paraContainerMobile)}>
                        {!readMore &&
                            <>
                                <div className={styles.aboutUsPara}>
                                    {parse(data.para[0])}
                                </div>
                                <div onClick={() => showMore()} className={styles.img}>
                                    <CustomImageComponent
                                        height={22}
                                        width={42}
                                        src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/showMore.webp"
                                        alt={"about-us-image"}
                                    />
                                </div>
                            </>
                        }
                        {readMore &&
                            <>
                                {data.para.map((item, index) => {
                                    return (
                                        <div className={styles.aboutUsPara} key={"data_para_" + index}>
                                            {parse(item)}
                                        </div>
                                    )
                                })
                                }
                            </>
                        }
                    </div>

                    <div className={multipleClassName(styles.paraContainer, styles.paraContainerPC)}>
                        {data.para.map((item, index) => {
                            return (
                                <div className={styles.aboutUsPara} key={"data.para_" + index}>
                                    {parse(item)}
                                </div>
                            )
                        })
                        }
                    </div>


                </div>
            </div> : <></>
    )
}

export default AboutUsComponent;
