import {IBannerDownloadButtonComponentProps, IFixedDownloadButtonProps} from "../../../lib/types/components";
import {BANNER_DOWNLOAD_BUTTON_TYPE, GET_APP_URL} from "../../../lib/utils/constants/GlobalConstants";
import {checkIsGetAppFb} from "../../../lib/utils/constants/PagesConstants";
import DisclaimerComponent from "../DisclaimerComponent/DisclaimerComponent";
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./BannerDownloadButtonComponentV2.module.css"

const BannerDownloadButtonComponentV2 = (props: IBannerDownloadButtonComponentProps) => {
    const {
        isUtmHotstar,
        fixedDownloadButtonData,
        bgColor = "#210131",
        iosCtaData,
        pageName
    } = props;

    // const {downloadUrl: apkUrl} = useDownloadContext();

    const isGetAppFb: boolean = checkIsGetAppFb(pageName);
    const isGetApp: boolean = pageName === GET_APP_URL;

    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        type: BANNER_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        iosCtaData: iosCtaData,
        pageName: pageName
    }
    return (
        <div className={multipleClassName("container-fluid", styles.mainContainer)} style={{backgroundColor: bgColor}}>
            {
                <>
                    <div style={isGetAppFb ? {padding: "0 0 10px 0"} : {padding: "0"}}
                         className={"container-fluid"}>
                        {/* <div className={"content"}>{props.blinkTextDownloadButton}</div> */}
                        <FixedDownloadButtonV2 {...fixedDownloadBtnProps} />
                        {
                            isGetApp && isUtmHotstar && <DisclaimerComponent/>
                        }

                    </div>
                </>
            }
        </div>
    )
}

export default BannerDownloadButtonComponentV2;
