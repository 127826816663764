import {
  ResolvingMetadata,
  Metadata,
  GetStaticProps,
  GetStaticPaths,
} from "next";
import IndexPageIndiaV2 from "../../../Layouts/India/IndexPageIndiaV2";
import { getData } from "../../../lib/network/api";
import { IHomeApiData, IHomePageLanguageData } from "../../../lib/types/home";
import {
  getWebsiteEnvironment,
  STAG_WEB_URL,
  WINZO_GLOBAL_URL,
} from "../../../lib/utils/constants/EnvironmentConstants";
import {
  HOME_PAGE,
  REVALIDATE,
  pagesWithLocale,
  HOME_PAGE_URL,
} from "../../../lib/utils/constants/GlobalConstants";
import {
  LANGUAGE_COUNTRY,
  COUNTRY,
} from "../../../lib/utils/constants/LocaleAndCountryConstants";
import {
  getMetaData,
  getStaticLocale,
  getLangugageToggleData,
  getCountryFromLocale,
  getStaticPagePath,
} from "../../../lib/utils/constants/PagesConstants";
import { getAdditionalDataHome } from "../../../lib/utils/helpers/additionalData";
import { getGlobalData, getNonApiData } from "../../app/utils/get-data";
import GetKwaiScript from "../../app/utils/GetKwaiScript";
import { IHomeUSApiData } from "../../../lib/types/home.us";
import { ABTestingData, BaseProps } from "../../../lib/types/Misc.type";

type Props = {
  apiData: IHomeApiData | IHomeUSApiData | any;
  autoScrollToElement: () => void;
  url: string;
  installStepContainer: React.RefObject<HTMLElement>;
  downloadUrl: string;
  androidUrl: string;
  abTestingData: ABTestingData;
} & BaseProps;

// type MetadataProps = {
//   params: { locale: string };
// };

// export async function generateMetadata(
//   { params }: MetadataProps,
//   parent: ResolvingMetadata
// ): Promise<Metadata> {
//   const locale = params.locale;
//   let homeData = await getData<IHomeApiData>(locale, HOME_PAGE, REVALIDATE);
//   const locales = pagesWithLocale.get(HOME_PAGE_URL)!.locales;
//   if (
//     locale === LANGUAGE_COUNTRY.PT_BR &&
//     getWebsiteEnvironment().baseUrl === WINZO_GLOBAL_URL
//   ) {
//     homeData = await getData<IHomeApiData>(locale, HOME_PAGE + "-winzoglobal");
//   }

//   return getMetaData(homeData, locales, locale, HOME_PAGE_URL);
// }

// export async function generateStaticParams() {
//   return getStaticLocale(
//     pagesWithLocale.get(HOME_PAGE_URL)?.locales!,
//     "locale"
//   );
// }

const Home = (props: Props) => {
  //   const data = await getGlobalData<IHomePageLanguageData>(
  //     locale,
  //     jsonName,
  //     pageName,
  //     languageToggleData,
  //     undefined,
  //     getAdditionalDataHome
  //   );

  return (
    <>
      <IndexPageIndiaV2 {...props} />
    </>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  let homePagePaths = getStaticPagePath(
    pagesWithLocale.get(HOME_PAGE_URL)?.locales!,
    "locale"
  );
  return {
    paths: homePagePaths,
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps = async (props) => {
  const locale = props.params!.locale! as string;
  const pageName: string = HOME_PAGE_URL;
  const jsonName: string = HOME_PAGE;

  const languageToggleData = getLangugageToggleData(
    pagesWithLocale,
    pageName,
    locale
  );

  const {
    appVersions,
    abTestingData,
    brandModelData,
    deviceArchitectureMapping,
  } = await getNonApiData(locale);

  const data = await getGlobalData<IHomePageLanguageData>(
    locale,
    jsonName,
    pageName,
    languageToggleData,
    HOME_PAGE_URL,
    getAdditionalDataHome
  );

  return {
    props: {
      ...data,
      appVersions,
      brandModelData,
      abTestingData,
      deviceArchitectureMapping,
    },
    revalidate: REVALIDATE,
  };
};

export default Home;
