"use client"
import Link from "next/link";
import {ILinkComponent} from "../../../lib/types/common";
import React, {CSSProperties, useEffect, useState} from "react";
import useIsMobile from "../../../lib/customHooks/isMobile";
import {getWebsiteEnvironment} from "../../../lib/utils/constants/EnvironmentConstants";
import { useDownloadContext } from "../../../lib/context/CommonContext";

const CustomLinkComponent = (props: React.PropsWithChildren<ILinkComponent>) => {
    let {locale} = useDownloadContext();
    
    if(props.locale) locale = props.locale;
    const {isMobileDevice} = useIsMobile()
    const [style, setStyle] = useState<CSSProperties | undefined>(undefined)

    useEffect(() => {
        if (isMobileDevice) {
            setStyle(props.mobStyle)
        } else {
            setStyle(props.desktopStyle)
        }
    }, [isMobileDevice]);

    return (
        <Link
            href={locale && !props.href?.includes("http") && locale !== getWebsiteEnvironment().defaultLocale
                ? props.href ? `/${locale}` + props.href : "#"
                : props.href ? props.href : "#"}
            replace={props.replace}
            prefetch={props.prefetch || false}
            style={{textDecoration: "none", ...style}}
            className={props.className}
            scroll={props.scroll}
            passHref={props.passHref}
            id={props.id}
            locale={false}
            key={props.className + "_" + props.href}
            onClick={props.onClick}
            suppressHydrationWarning
        >{props.children}</Link>
    )
}

export default CustomLinkComponent