import {ISmsSentModalProps} from '../../../lib/types/components';
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import styles from "./SmsSentModal.module.css"

const SmsSentModal = (props: ISmsSentModalProps) => {

    const {smsSent, smsSentMsg, setSmsSent, number, smsSentModalData} = props;

    const handleCancelClick = (e) => {
        setSmsSent(false);
        const html: any = document.querySelector("html");
        html.style.overflowY = "auto";
    }

    return (
        smsSentModalData ?
            <div
                onClick={handleCancelClick}
                className={smsSent ? ((navigator.userAgent.indexOf("Firefox") !== -1) ? styles.containerOpenFirefox : styles.containerOpen) : styles.containerClose}
            >
                <div
                    className={styles.modalContainer}
                >
                    <div className={styles.smsDetailsContainer}>
                            <span>
                                <CustomImageComponent
                                    layout='fixed'
                                    src={"https://d3g4wmezrjkwkg.cloudfront.net/website/images/green_tick_image.webp"}
                                    width={48}
                                    height={48}
                                    alt={"sms-successful-sent"}
                                />
                            </span>
                        <p>{smsSentMsg}<span>{number}</span></p>
                    </div>
                    <div className={styles.lineSeperator}>
                        <CustomImageComponent
                            layout='fixed'
                            src={"https://d3g4wmezrjkwkg.cloudfront.net/website/images/gameFoulsLine.webp"}
                            width={800}
                            height={9}
                            alt={"sms-line"}
                        />
                    </div>
                    <div className={styles.qrContainer}>
                        <div className={styles.textContainer}>
                            <p>{smsSentModalData.text2}</p>
                            <p>
                                {smsSentModalData.text3}
                            </p>
                        </div>
                        <div>
                            <CustomImageComponent
                                layout='fixed'
                                src={smsSentModalData.qrCode}
                                width={280}
                                height={280}
                                alt="sms-QR-code"
                            />
                        </div>
                    </div>
                    <div className={styles.cancelButtonContainer} onClick={handleCancelClick}>
                        <CustomImageComponent
                            layout='fixed'
                            src={'https://d3g4wmezrjkwkg.cloudfront.net/website/images/cancel_botton_shape.webp'}
                            width={32}
                            height={32}
                            alt="sms-close-popup"
                        />
                    </div>
                </div>
            </div> : <></>
    )

}

export default SmsSentModal;